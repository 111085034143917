@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
}

#root {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

main {
  flex: 2;
}

.cls-1 {
  fill: #fff;
}

@layer components {
  .menu_item {
    @apply hover:bg-blue-200 transition-all w-full dark:hover:bg-[#4f587e];
  }
}
